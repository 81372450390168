<template>
    <b-card title="Filtro">
        <b-overlay
            variant="white"
            :show="showLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
            <b-row>
            <b-col xl="2" sm="12">
                <b-form-group label="Año" label-for="year">
                    <v-select
                        v-model="filter.year"
                        class="pt-0" label="type" name="type"
                        :options="years"
                    />
                </b-form-group>
            </b-col>
            <b-col xl="2" sm="12">
                <b-form-group label="Empresa" label-for="company">
                    <v-select
                        v-model="filter.company"
                        class="pt-0" label="name" name="name"
                        :reduce="(option) => option.id"
                        :options="companies"
                    />
                </b-form-group>
            </b-col>
            <b-col xl="2" sm="12">
                <b-form-group label="Segmento" label-for="segment">
                    <v-select
                        v-model="filter.segment"
                        class="pt-0" label="name" name="name"
                        :reduce="(option) => option.id"
                        :options="segments"
                    />
                </b-form-group>
            </b-col>
            <!--
            <b-col xl="2" sm="12">
                <b-form-group label="Instalación Activa" label-for="installation_active">
                    <v-select
                        v-model="filter.installation_active"
                        :reduce="(option) => option.id"
                        class="pt-0" label="name" name="name"
                        :options="installations_active"
                    />
                </b-form-group>
            </b-col>
            -->
            <b-col xl="4" sm="12">
                <b-button
                    class="mt-2"
                    variant="primary"
                    @click="search"
                >
                    Filtrar
                </b-button>

                <b-button
                    class="mt-2 ml-2"
                    variant="primary"
                    @click="exportExcel"
                >
                    Exportar
                </b-button>
            </b-col>
        </b-row>
        </b-overlay>
    </b-card>

</template>

<script>
import {BRow, BCol, BCard, BFormGroup, BFormInput, BButton, BOverlay} from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"

const {mapFields} = createHelpers({
    getterType: 'dashboardMetrics/getField',
    mutationType: 'dashboardMetrics/updateField',
})

export default {
    name: "FilterComponent",
    components: {
        BOverlay,
        BButton,
        BRow,
        BFormGroup,
        BCol,
        BCard,

        vSelect,
    },
    data() {
        return {
            years: [],
            companies: [
                { id: 1, name: 'ENDESA SOLUCIONES'},
                { id: 2, name: 'ENDESA ENERGIA' },
                { id: 3, name: 'ENDESA X'}
            ],
            segments: [
                {id: 1, name: 'Empresas'},
                {id: 2, name: 'Grandes Clientes'},
                {id: 3, name: 'Gran Publico'},
                {id: 4, name: 'Innovación desarrollo de negocio'},
            ],
            installations_active: [
                {id: 0, name: 'No'},
                {id: 1, name: 'Si'},
            ],
        }
    },
    computed: {
        ...mapState('dashboardMetrics', ['dashboard_metrics']),
        ...mapFields({
            dashboard_metrics: 'dashboard_metrics',
            totals: 'totals',
            installation_list: 'installation_list',
            tabIndex: 'tabIndex',
            filter: 'filter',
            showLoading: 'showLoading',
            contentName: 'contentName'
        }),
    },
    created() {
        this.setYears()
    },

    methods: {
        setYears: function (){
            const date = new Date()
            let year = date.getFullYear()

            this.years = [year, year-1]
            this.filter.year = year
        },
        search(){
            this.showLoading = true

            this.$store.dispatch('dashboardMetrics/getDashboardMetrics', {
                filter: this.filter,
            })
                .then(response => {
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        exportExcel() {
            this.showLoading = true
            this.$store.dispatch('dashboardMetrics/generateInformDocumentExpirationExcel', {
                filter: this.filter,
            })
                .then(response => {
                    this.downloadExcel(response, `cuadro_de_mandos`)
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        downloadExcel(response, filename) {
            const blob = new Blob([response])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.setAttribute('target', '_blank')
            link.download = `${filename}.xlsx`
            link.click()
        },
    }
}
</script>

<style lang="scss">

</style>